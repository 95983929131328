<template>
  <app-event event-id="glowbyte-2020" auth="login-only">
    <template v-slot:logo>
      <v-img src="https://cdn.your-quest.com/common/logo.png" class="ma-4" />
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";

export default {
  name: "AppPageEvent",
  components: {
    AppEvent,
  },
};
</script>
